<template>
  <div class="common_dialog_wrapper" ref="home_dialog">
    <el-dialog :visible="visible" top="0" @close="close" :append-to-body="true" center>
      <div class="dialog_body">
        <button
          @click="handleSubChangePage()"
          class="circle-button arrow arrow-left"
          :class="{ 'ar-arrow': lang === 'ar' }"
        >
          <i class="el-icon-arrow-left"></i>
        </button>
        <button
          @click="handleAddChangePage()"
          class="circle-button arrow arrow-right"
          :class="{ 'ar-arrow': lang === 'ar' }"
        >
          <i class="el-icon-arrow-right"></i>
        </button>
        <p class="title">{{ notificationsItem.subject }}</p>
        <div v-html="notificationsItem.document"></div>
      </div>
      <div slot="footer">
        <el-row type="flex" justify="space-between" class="footer">
          <el-col :span="8">
            <a @click="showLiveChat" class="liveChat" data-testid="liveChat">
              <img src="@/assets/images/dialog/chat.png" alt />
              <span class="help" v-html="$t('common.liveChat.desc')"></span>
            </a>
          </el-col>
          <el-col :span="16" class="pagination-div">
            <el-pagination
              background
              :pager-count="5"
              layout="prev, pager, next"
              @current-change="handleCurrentChange"
              :total="total"
              :page-size="1"
              :current-page="currentPage"
            >
            </el-pagination>
          </el-col>
        </el-row>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'Notice',
  props: {
    notificationVisible: Boolean,
    notifications: Array
  },
  data() {
    return {
      notificationContent: null,
      visible: false,
      currentPage: 1
    };
  },
  watch: {
    notificationVisible(bool) {
      this.visible = bool;
    },
    visible(bool) {
      this.$emit('update:notificationVisible', bool);
    }
  },
  methods: {
    close() {
      this.$emit('update:notificationVisible', false);
    },
    handleCurrentChange(val) {
      this.currentPage = val;
    },
    handleAddChangePage() {
      this.currentPage < this.notifications.length && this.currentPage++;
    },
    handleSubChangePage() {
      this.currentPage > 1 && this.currentPage--;
    }
  },
  computed: {
    lang() {
      return this.$store.state.common.lang;
    },
    notificationsItem() {
      const currentNotification = this.notifications[this.currentPage - 1];
      let currentLangNotiDoc = currentNotification.notificationDocuments.find(doc => doc.language_code === this.lang);
      if (!currentLangNotiDoc || currentLangNotiDoc.is_del === 1) {
        currentLangNotiDoc = currentNotification.notificationDocuments.find(doc => doc.language_code === 'en_US');
      }
      if (!currentLangNotiDoc) {
        return {
          subject: '',
          document: ''
        };
      }
      return {
        subject: currentLangNotiDoc.subject,
        document: currentLangNotiDoc.document
      };
    },
    total() {
      return this.notifications.length;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/components/notification/notice.scss';
</style>
